import { ReviewRating, Heading } from '../../../components';
import { FormattedMessage } from 'react-intl';

import { default as iconShare } from './iconShare.svg';
import { default as iconHeartUnsaved } from './iconHeartUnsaved.svg';
import { default as iconHeartSaved } from './iconHeartSaved.svg';
import { default as iconReport } from './iconReport.svg';

import css from './SectionDetailsSummarized.module.scss';

const SectionDetailsSummarized = props => {
  const {
    title,
    reviews,
    reviewsAverage,
    listingFieldsMap,
    publicData,
    currentUser,
    onToggleFavorites,
    listing,
  } = props;

  // const reviewsExists = reviews.length > 0 && reviewsAverage;
  const spanReviewRating = (
    <>
      <span className={css.spanContainer}>
        <span className={css.reviewRating}>
          <ReviewRating
            rating={reviewsAverage ? reviewsAverage : 0}
            className={css.rating}
            reviewStarClassName={css.star}
            showAverageRatingValue={true}
            reviewAverageValueClassName={css.averageValue}
          />
        </span>
      </span>
      <span className={css.spanContainer}>
        <span className={css.reviewCountBox}>
          <FormattedMessage id={'OrderPanel.reviewCount'} values={{ count: reviews.length }} />
        </span>
      </span>
    </>
  );

  const getSelectedFieldElementsMultiEnum = field => {
    const fieldsArr = [];
    const selectedFields = publicData?.[field];
    if (selectedFields) {
      for (let i = 0; i < selectedFields.length; i++) {
        const element = (
          <span key={`field-${i}`}>
            <FormattedMessage id={`Taxonomy.${field}.${selectedFields[i]}`} />
          </span>
        );
        fieldsArr.push(element);
        if (i !== selectedFields.length - 1) {
          fieldsArr.push(<span key={`comma-${i}`}>, </span>);
        }
      }
    }
    return fieldsArr;
  };

  const paragraphCategory = <p>{getSelectedFieldElementsMultiEnum('category')}</p>;

  const spanGeography = (
    <span className={css.spanContainer}>
      <FormattedMessage id={'ListingPage.geography'} />
      <span>: </span>
      <strong>{getSelectedFieldElementsMultiEnum('location_district')}</strong>
    </span>
  );

  const spanCommunicationLanguages = (
    <span className={css.spanContainer}>
      <FormattedMessage id={'ListingPage.communicationLanguages'} />
      <span>: </span>
      <strong>{getSelectedFieldElementsMultiEnum('language_of_service')}</strong>
    </span>
  );

  const isFavorite = currentUser?.attributes.profile.privateData?.favorites?.includes(
    listing.id.uuid
  );

  const toggleFavorites = () => {
    onToggleFavorites(isFavorite);
  };

  return (
    <div className={css.sectionDetailsSummarized}>
      <div className={css.row}>
        <div className={css.col12}>
          <Heading as="h2" rootClassName={css.listingTitle}>
            {title}
          </Heading>
        </div>
      </div>
      <div className={css.row}>
        <div className={css.col12}>{paragraphCategory}</div>
      </div>
      <div className={css.row}>
        <div className={css.colOtherDetailsDesktop}>
          <p>
            {spanReviewRating}
            {spanGeography}
            <span className={css.spanContainer}>&#8226;</span>
            {spanCommunicationLanguages}
          </p>
        </div>
        <div className={css.colOtherDetailsMobile}>
          <div className={css.col12}>
            <div className={css.sectionReviewMobile}>{spanReviewRating}</div>
          </div>
          <div className={css.col12}>{spanGeography}</div>
          <div className={css.col12}>{spanCommunicationLanguages}</div>
        </div>
        <div className={css.colShareWishlist}>
          <div className={css.wishlistContainer}>
            <p>
              {/* <span className={css.spanContainer}>
                <span className={css.shareLink}>
                  <img className={css.iconShare} src={iconShare} />
                  <FormattedMessage id={'ListingPage.share'} />
                </span>
              </span> */}
              <span className={css.spanContainer}>
                <span onClick={() => toggleFavorites()} className={css.addToWishlistLink}>
                  {isFavorite ? (
                    <>
                      <img className={css.iconHeartUnsaved} src={iconHeartSaved} />
                      <FormattedMessage id="ListingPage.addedToWishlist" />
                    </>
                  ) : (
                    <>
                      <img className={css.iconHeartUnsaved} src={iconHeartUnsaved} />
                      <FormattedMessage id="ListingPage.addToWishlist" />
                    </>
                  )}
                </span>
              </span>
              {/* <span className={css.spanContainer}>
                <span className={css.reportLink}>
                  <img className={css.iconReport} src={iconReport} />
                  <FormattedMessage id={'ListingPage.report'} />
                </span>
              </span> */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionDetailsSummarized;
