import { useEffect, useState } from 'react';
import listingCss from '../ListingPage.module.scss';
import css from './SectionVideoMaybe.module.scss';

const YOUTUBE_REGEX = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/gm;
const isTestEnv = process.env.NODE_ENV === 'test';

const SectionVideoMaybe = props => {
  const { listing } = props;
  const { publicData } = listing.attributes;

  const [validVideoLink, setValidVideoLink] = useState(null);

  useEffect(() => {
    const listingVideoLink = publicData?.listingVideoLink;
    if (YOUTUBE_REGEX.test(listingVideoLink)) {
      const videoId =
        new URL(listingVideoLink).searchParams.get('v') || listingVideoLink.split('/').pop();
      const embedLink = `https://www.youtube.com/embed/${videoId}?rel=0&controls=0`;
      setValidVideoLink(embedLink);
    }
  }, [publicData]);

  return validVideoLink ? (
    <div className={listingCss.sectionText}>
      <iframe
        className={css.youtubeVideoContainer}
        src={validVideoLink}
        title="Video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </div>
  ) : null;
};

export default SectionVideoMaybe;
