import { Heading } from '../../../components';

import css from './SectionThingsToKnowMaybe.module.scss';
import listingCss from '../ListingPage.module.scss';

import appConfig from '../../../config/configDefault';
import { default as iconGuests } from './iconGuests.svg';
import { default as iconCancellationPolicy } from './iconCancellationPolicy.svg';
import { default as iconRestrictions } from './iconRestrictions.svg';
import { default as iconLocation } from './iconLocation.svg';
import { default as iconRequirements } from './iconRequirements.svg';
import { FormattedMessage } from 'react-intl';
import { EE_LOCALE, RU_LOCALE } from '../../../util/types';

const SectionThingsToKnowMaybe = props => {
  const { listingFieldsMap, publicData } = props;

  const Detail = (icon, title, description) => {
    return {
      icon: icon,
      title: title,
      description: description,
    };
  };

  const getSelectedFieldElementsEnum = field => {
    const selectedField = publicData?.[field];
    if (selectedField) {
      const element = (
        <span>
          <FormattedMessage id={`Taxonomy.${field}.${selectedField}`} />
        </span>
      );
      return element;
    }
  };

  const getSelectedFieldElementsText = field => {
    const selectedField = publicData?.[field];
    if (selectedField) {
      const element = <span>{selectedField}</span>;
      return element;
    }
  };

  let locale_suffix = '';
  switch (appConfig.localization.locale) {
    case EE_LOCALE:
      locale_suffix = '_et';
      break;
    case RU_LOCALE:
      locale_suffix = '_ru';
      break;
  }

  const requirements_fieldname = 'requirements' + locale_suffix;
  const restrictions_fieldname = 'restrictions' + locale_suffix;

  const detailsArr = [
    // Detail(iconGuests, 'guests', getSelectedFieldElements('location_district')),
    Detail(
      iconCancellationPolicy,
      'cancellation_policy',
      getSelectedFieldElementsEnum('cancellation_policy')
    ),
    Detail(iconRestrictions, 'restrictions', getSelectedFieldElementsText(restrictions_fieldname)),
    // Detail(iconLocation, 'locationTitle', getSelectedFieldElements('location_district')),
    Detail(iconRequirements, 'requirements', getSelectedFieldElementsText(requirements_fieldname)),
  ];

  return (
    <div className={css.sectionThingsToKnow}>
      <Heading as="h2" rootClassName={listingCss.sectionHeading}>
        <FormattedMessage id={`ListingPage.thingsToKnowTitle`} />
      </Heading>
      <div className={css.containerDetails}>
        <div className={css.row}>
          {detailsArr.map((detail, index) => {
            return (
              <div key={`toknow-${index}`} className={css.colDetail}>
                <p className={css.title}>
                  <img className={css.icon} src={detail.icon} />
                  <FormattedMessage id={`Taxonomy.${detail.title}.label`} />
                </p>
                <p className={css.description}>{detail.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SectionThingsToKnowMaybe;
