import { FormattedMessage } from 'react-intl';

import css from './SectionDetailsGridMaybe.module.scss';

import { default as iconMap } from './iconMap.svg';
import { default as iconService } from './iconService.svg';
import { default as iconTags } from './iconTags.svg';
import { default as iconCommunicationLanguages } from './iconCommunicationLanguages.svg';
import { default as iconServiceLanguages } from './iconServiceLanguages.svg';
import { default as iconHoursOfCommunication } from './iconHoursOfCommunication.svg';
import { default as iconDuration } from './iconDuration.svg';
import { default as iconLocationPreference } from './iconLocationPreference.svg';

const SectionsDetailsGridMaybe = props => {
  const { listingFieldsMap, publicData } = props;

  const Detail = (icon, title, description) => {
    return {
      icon: icon,
      title: title,
      description: description,
    };
  };

  const getSelectedFieldElements = field => {
    const fieldsArr = [];
    const selectedFields = publicData?.[field];
    if (selectedFields) {
      for (let i = 0; i < selectedFields.length; i++) {
        let element = (
          <span key={`selected_field-${i}`}>
            <FormattedMessage id={`Taxonomy.${field}.${selectedFields[i]}`} />
          </span>
        );
        fieldsArr.push(element);
        if (i !== selectedFields.length - 1) {
          fieldsArr.push(<span key={`selected_comma-${i}`}>, </span>);
        }
      }
    }
    return fieldsArr;
  };

  const getDurationElements = () => {
    const duration = publicData?.duration;
    const minDurationValid = duration?.min || !isNaN(duration?.min);
    const maxDurationValid = duration?.max || !isNaN(duration?.max);
    return (
      <>
        {minDurationValid && (
          <span>
            <FormattedMessage
              id={`Taxonomy.duration.minimum`}
              values={{ minValue: duration.min }}
            />
          </span>
        )}
        {minDurationValid && maxDurationValid && <span>, </span>}
        {maxDurationValid && (
          <span>
            <FormattedMessage
              id={`Taxonomy.duration.maximum`}
              values={{ maxValue: duration.max }}
            />
          </span>
        )}
      </>
    );
  };

  const detailsArr = [
    Detail(iconMap, 'area', getSelectedFieldElements('location_district')),
    Detail(iconService, 'service', getSelectedFieldElements('category')),
    // Detail(iconTags, 'tags', getSelectedFieldElements('category')),
    Detail(
      iconCommunicationLanguages,
      'communicationLanguages',
      getSelectedFieldElements('language_of_service')
    ),
    Detail(
      iconServiceLanguages,
      'serviceLanguage',
      getSelectedFieldElements('language_of_service')
    ),
    Detail(
      iconLocationPreference,
      'locationPreference',
      getSelectedFieldElements('location_preference')
    ),
    Detail(iconDuration, 'duration', getDurationElements()),
    // Detail(
    //   iconHoursOfCommunication,
    //   'hoursOfCommunication',
    //   getSelectedFieldElements('language_of_service')
    // ),
  ];

  return (
    <div className={css.sectionDetailsGrid}>
      <div className={css.row}>
        {detailsArr.map((detail, index) => {
          return (
            <div key={`detail-${index}`} className={css.colDetail}>
              <img className={css.icon} src={detail.icon} />
              <p className={css.title}>
                <FormattedMessage id={`ListingPage.${detail.title}`} />:
              </p>
              <p className={css.description}>{detail.description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SectionsDetailsGridMaybe;
