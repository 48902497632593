import { ResponsiveImage } from '../../../components';
import css from './ImageGridGallery.module.scss';

const MAX_PHOTOS_GRID = 5;

const ImageGridGallery = props => {
  const { images } = props;

  const gridLength = images.length > MAX_PHOTOS_GRID ? MAX_PHOTOS_GRID : images.length;
  const gridImages = [];
  for (let i = 0; i < gridLength; i++) {
    const listingImage = images[i];
    const variants = listingImage
      ? Object.keys(listingImage?.attributes?.variants).filter(k => k.startsWith('scaled'))
      : [];

    gridImages.push({
      image: listingImage,
      variants: variants,
    });
  }

  const getResponsiveImage = (index, classes) => {
    return (
      <ResponsiveImage
        rootClassName={classes}
        alt={''}
        image={gridImages[index].image}
        variants={gridImages[index].variants}
      />
    );
  };

  return (
    <>
      {gridImages.length > 0 && (
        <div className={css.row}>
          {gridImages.length === 1 && (
            <div className={css.col12}>{getResponsiveImage(0, css.imageCol)}</div>
          )}
          {gridImages.length === 2 && (
            <>
              <div className={[css.col6, css.imageBorder_right].join(' ')}>
                {getResponsiveImage(0, `${css.imageCol} ${css.length2}`)}
              </div>
              <div className={css.col6}>
                {getResponsiveImage(1, `${css.imageCol} ${css.length2}`)}
              </div>
            </>
          )}
          {gridImages.length === 3 && (
            <>
              <div className={css.col6}>
                {getResponsiveImage(0, `${css.imageCol} ${css.length3}`)}
              </div>
              <div className={css.col6}>
                <div className={[css.row, css.imageBorder_left].join(' ')}>
                  <div className={[css.col12, css.imageBorder_bottom].join(' ')}>
                    {getResponsiveImage(1, `${css.imageRow} ${css.length3}`)}
                  </div>
                  <div className={css.col12}>
                    {getResponsiveImage(2, `${css.imageRow} ${css.length3}`)}
                  </div>
                </div>
              </div>
            </>
          )}
          {gridImages.length === 4 && (
            <>
              <div className={css.col4}>
                {getResponsiveImage(0, `${css.imageCol} ${css.length4} ${css.imageBorder_right}`)}
              </div>
              <div className={css.col4}>
                <div className={[css.row, css.imageBorder_left, css.imageBorder_right].join(' ')}>
                  <div className={[css.col12, css.imageBorder_bottom].join(' ')}>
                    {getResponsiveImage(2, `${css.imageRow} ${css.length4}`)}
                  </div>
                  <div className={css.col12}>
                    {getResponsiveImage(3, `${css.imageRow} ${css.length4}`)}
                  </div>
                </div>
              </div>
              <div className={css.col4}>
                {getResponsiveImage(1, `${css.imageCol} ${css.length4}`)}
              </div>
            </>
          )}
          {gridImages.length >= 5 && (
            <>
              <div className={css.col3}>
                {getResponsiveImage(0, `${css.imageCol} ${css.length5} ${css.imageBorder_right}`)}
              </div>
              <div className={css.col3}>
                {getResponsiveImage(1, `${css.imageCol} ${css.length5} ${css.imageBorder_right}`)}
              </div>
              <div className={css.col3}>
                <div className={[css.col12, css.imageBorder_bottom].join(' ')}>
                  {getResponsiveImage(2, `${css.imageRow} ${css.length5}`)}
                </div>
                <div className={css.col12}>
                  {getResponsiveImage(3, `${css.imageRow} ${css.length5}`)}
                </div>
              </div>
              <div className={css.col3}>
                {getResponsiveImage(4, `${css.imageCol} ${css.length5} ${css.imageBorder_left}`)}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ImageGridGallery;
